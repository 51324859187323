import React, { useRef, useEffect } from 'react';
import { nav } from '../data';

const MobileNav = ({ onClose }) => {
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <div className='mobile-nav-container bg-main/80 w-full h-full' ref={menuRef}>
      <ul className='h-full flex flex-col justify-center items-center gap-y-8'>
        {nav.map((item, index) => {
          const { href, name } = item;
          return (
            <li key={index}>
              <a className='link text-white text-xl' href={href} onClick={onClose}>
                {name}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default MobileNav;
