import React from 'react';
import ArrowDown from './ArrowDown';
import LogoFlammarion from '../assets/img/logo.png'
import Whatsapp from './Whatsapp';

const Hero = () => {

  return (
    <section className='section flex flex-col justify-between h-screen bg-hero_servicemobile lg:bg-hero_service bg-no-repeat bg-cover bg-top relative'>
      <img className='flex absolute w-52 top-2 left-2 lg:hidden shadow-md' src={LogoFlammarion} alt='' />
      <ArrowDown />
      <Whatsapp />
    </section>
  );
};

export default Hero;
