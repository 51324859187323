import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import Image from '../assets/img/contato-eletricista.jpeg'
import Social from './Social';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    message: '',
  });
  const [formError, setFormError] = useState({
    name: '',
    phone: '',
    email: '',
    message: '',
  });
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const validateForm = () => {
    const errors = {};
    if (!formData.name.trim()) {
      errors.name = 'O nome é obrigatório';
    }
    if (!formData.phone.trim()) {
      errors.phone = 'O telefone é obrigatório';
    }
    if (!formData.email.trim()) {
      errors.email = 'O e-mail é obrigatório';
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      errors.email = 'Insira um e-mail válido';
    }
    if (!formData.message.trim()) {
      errors.message = 'A mensagem é obrigatória';
    }

    setFormError(errors);

    return Object.keys(errors).length === 0;
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    // Configuração do emailjs - Substitua com suas próprias credenciais
    const serviceId = '';
    const templateId = '';
    const userId = '';

    emailjs
      .send(serviceId, templateId, formData, userId)
      .then((response) => {
        alert('E-mail enviado com sucesso, agradeçemos seu contato.', response);
        setFormData({
          name: '',
          phone: '',
          email: '',
          message: '',
        });
        setFormError({});
      })
      .catch((error) => {
        alert('Erro ao enviar e-mail, tente nos contatar pelo WhatsApp.', error);
      });
  };

  return (
    <section>
      <h2 className="text-white text-center bg-gradient-to-r from-dark/90 to-light text-xl lg:text-3xl font-extrabold uppercase py-6" >
        Fale com um consultor
      </h2>
      <div className='container mx-auto flex flex-col lg:flex-row items-center m-8'>
        {/* Formulário */}
        <form
          data-aos='fade-right'
          data-aos-offset='100'
          data-aos-delay='600'
          className='flex flex-col border lg:rounded-l-3xl rounded-3xl lg:w-1/2 w-full h-[550px] px-10 py-8 gap-y-4 bg-dark text-white'
          onSubmit={handleSubmit}
        >
          <input
            className={`bg-transparent border-b py-3 outline-none w-full transition-all placeholder-white ${formError.name && 'border-red-500'
              }`}
            type='text'
            name='name'
            value={formData.name}
            onChange={handleChange}
            placeholder='Seu nome'
          />
          {formError.name && (
            <p className='text-red-500 text-sm mt-1'>{formError.name}</p>
          )}
          <input
            className={`bg-transparent border-b py-3 outline-none w-full placeholder-white transition-all resize-none ${formError.phone && 'border-red-500'
              }`}
            type='text'
            name='phone'
            value={formData.phone}
            onChange={handleChange}
            placeholder='Seu Telefone'
          />
          {formError.phone && (
            <p className='text-red-500 text-sm mt-1'>{formError.phone}</p>
          )}
          <input
            className={`bg-transparent border-b py-3 outline-none w-full placeholder-white transition-all resize-none ${formError.email && 'border-red-500'
              }`}
            type='text'
            name='email'
            value={formData.email}
            onChange={handleChange}
            placeholder='Seu Email'
          />
          {formError.email && (
            <p className='text-red-500 text-sm mt-1'>{formError.email}</p>
          )}
          <textarea
            className={`bg-transparent border-b py-6 outline-none w-full  placeholder-white transition-all h-52 ${formError.message && 'border-red-500'
              }`}
            name='message'
            value={formData.message}
            onChange={handleChange}
            placeholder='Deixe sua mensagem'
          ></textarea>
          {formError.message && (
            <p className='text-red-500 text-sm mt-1'>{formError.message}</p>
          )}
          <div className='w-full flex justify-end '>
            <button
              type='submit'
              className='flex btn btn-sm bg-white hover:bg-slate-200 w-28 text-dark mt-5 items-center justify-center text-center'
            >
              Enviar
            </button>
          </div>
        </form>
        {/* Imagem */}
        <div
          className='hidden md:flex relative pl-6 fade-in'
          data-aos='fade-left'
          data-aos-offset='100'
          data-aos-delay='600'
        >
          <div
            className='absolute inset-0 bg-gradient-to-l from-transparent to-light '></div>
          <img
            src={Image}
            alt=""
            className='flex contain h-[550px] rounded-r-3xl'
          />
        </div>

      </div>
      {/* ocultado links sociais até autorização do cliente */}
      <div className=' text-center bg-main py-4 md:py-6 '>
        <Social />
      </div>
    </section>
  );
};

export default Contact;
