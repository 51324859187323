import React from 'react'
import ServicesSlider from './ServicesSlider'
import LogoLateral from '../assets/img/Logo-Lateral.png'


const Service = () => {
  return (
    <section id='Service' className=' h-full flex flex-col'>
      {/* bg-gray-200 */}
      <div className=' w-full mx-auto p-0'>

        <div className='mx-auto'>
          <h1 className=' text-white  text-center bg-gradient-to-r from-dark to-light text-xl lg:text-3xl font-extrabold uppercase py-6 '>
            Principais Serviços
          </h1>

        </div>
        <div className='lg:px-11 mx-auto'>
          <ServicesSlider /></div>
        <div className='flex items-end justify-end -mr-1 lg:-mt-40 pb-6 ' >
          <img src={LogoLateral} alt="Logo Flammarion Energia" className="w-32 lg:w-52" />
        </div>
      </div>
    </section>
  )
}

export default Service