/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { HiMenuAlt4, HiOutlineX } from 'react-icons/hi';
import LogoFlammarion from '../assets/img/logo.png';
import MobileNav from '../components/MobileNav';
import Nav from '../components/Nav';
import { header } from '../data';

const Header = () => {
  const [mobileNav, setMobile] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const { btnText } = header;

  useEffect(() => {
    window.addEventListener('scroll', () => {
      window.scrollY > 60 ? setIsActive(true) : setIsActive(false);
    });
  });

  const closeMobileNav = () => {
    setMobile(false);
  };

  return (
    <header
      className={`${isActive ? 'lg:top-0 bg-none lg:bg-second lg:shadow-2xl' : 'lg:top-0'
        } py-6 lg:py-4 fixed w-full transition-all z-30 text-light font-bold bg-none`}
    >
      <div className='container mx-auto flex justify-between items-center '>
        <a
          href='#'
          data-aos='fade-down'
          data-aos-delay='1000'
        >
          <img className='w-60 hidden lg:flex' src={LogoFlammarion} alt='' />
        </a>

        <div
          className='hidden lg:flex'
          data-aos='fade-down'
          data-aos-delay='1200'
        >
          <Nav />
        </div>

        <a
          data-aos='fade-down'
          data-aos-delay='1400'
          className='btn btn-sm btn-outline hidden lg:flex w-60'
          href="https://api.whatsapp.com/send?phone=5511947420443"
          target="_blank"
          rel="noopener noreferrer"
        >
          {btnText}
        </a>

        <button className='lg:hidden -ml-12' onClick={() =>
          setMobile(!mobileNav)}>
          {mobileNav ? (
            <HiOutlineX className='text-2xl text-dark' />
          ) : (
            <HiMenuAlt4 className='text-2xl text-dark' />
          )}
        </button>
        
        <div
          className={`${mobileNav ? 'left-0' : '-left-full'
            } fixed top-0 bottom-0 w-[60vw] lg:hidden transition-all`}
        >
          <MobileNav onClose={closeMobileNav} />
        </div>
      </div>
    </header>
  );
};

export default Header;