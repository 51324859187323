
import ImgService1 from './assets/img/Service1.png'
import ImgService2 from './assets/img/Service2.png'
import ImgService3 from './assets/img/Service3.png'
import LogoImg from './assets/img/logo.png'


export const header = {
  logo: LogoImg,
  btnText: 'FALE CONOSCO',
};

export const nav = [
  { name: 'HOME', href: '/' },
  { name: 'QUEM SOMOS', href: '#About' },
  { name: 'SERVIÇOS', href: '#Service' },
];

export const about = [
  {
    id: 1,
    title: 'Missão',
    subtitle: [
      { name: 'Proporcionar soluções profissionais e inovadoras de energia elétrica para a indústria, condomínios comerciais e residenciais, garantindo segurança, qualidade e confiabilidade, em conformidade com as normas técnicas nacionais e internacionais, agregando valor à sociedade.' }
    ],
  },
  {
    id: 2,
    title: 'Visão',
    subtitle: [
      { name: 'Ser uma empresa de referência nacional em soluções de energia elétrica.' }
    ],
  },
  {
    id: 3,
    title: 'Valores e Princípios',
    subtitle: [
      { name: 'Atender o cliente com qualidade, honestidade, dedicação nas suas necessidades promovendo a satisfação de todas as partes, incluindo clientes, funcionários, fornecedores e a empresa, trabalhando em equipe criando um vínculo pessoal e profissional.' }
    ],
  },
];

export const serviceSlider = [
  {
    id: 1,
    title: 'Industrial, Residencial, Comercial',
    image: < ImgService1 />,
    subtitle: [
      { name: 'Entradas coletivas e individuais' },
      { name: 'Cabines primárias' },
      { name: 'Dimensionamentos de circuitos especiais' },
      { name: 'Infraestrutura elétrica' },
      { name: 'Entrada de energia com medição indireta' },
      { name: 'Reformas e atualizações de quadros de distribuição' },
    ],
    link: 'https://api.whatsapp.com/send?phone=5511947420443',
    btnLink: 'Saiba Mais',
    btnIcon: 'ArrowRightImg',
  },

  {
    id: 2,
    title: 'Predial',
    image: < ImgService2 />,
    subtitle: [
      { name: 'Projetos elétricos' },
      { name: 'Cabines de barramento' },
      { name: 'Implementações bia bus-way' },
      { name: 'Entrada de energia' },
      { name: 'Prumadas elétricas' },
      { name: 'Regularizações elétricas para AVCB' },
    ],
    link: 'https://api.whatsapp.com/send?phone=5511947420443',
    btnLink: 'Saiba Mais',
    btnIcon: 'ArrowRightImg',
  },
  {
    id: 3,
    title: 'SUSTENTÁVEL',
    image: < ImgService3 />,
    subtitle: [
      { name: 'Implementações de sistema fotovoltaicos' },
      { name: 'Instalações de carregadores e tomadas veiculares' },
      { name: 'Gestão de consumo e faturas para recarga veicular' },
    ],
    link: 'https://api.whatsapp.com/send?phone=5511947420443',
    btnLink: 'Saiba Mais',
    btnIcon: 'ArrowRightImg',
  },
];

export const footer = {
  logo: LogoImg,
  links: [
    { name: 'Home', href: '/' },
    { name: 'Quem Somos', href: '/' },
    { name: 'Nossos Serviços', href: '/' },
  ],
};

