import React from 'react';
import { IoIosArrowDown } from 'react-icons/io';

const ArrowDown = () => {
  const handleButtonClick = () => {
    // Avançar uma tela inteira
    window.scrollTo({ top: window.innerHeight, behavior: 'smooth' });
  };

  return (
    <button className="absolute bottom-0 flex justify-center w-full z-10" onClick={handleButtonClick}>
      <IoIosArrowDown className="text-main text-7xl mb-10 animate-bounce" />
    </button>
  );
};

export default ArrowDown;
