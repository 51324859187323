import React, { useState, useEffect } from 'react';

// Importe as imagens dos seus parceiros
import Parceiro1 from '../assets/img/Parceiro1.png';
import Parceiro2 from '../assets/img/Parceiro2.png';
import Parceiro3 from '../assets/img/Parceiro3.png';
import Parceiro4 from '../assets/img/Parceiro4.png';
import Parceiro5 from '../assets/img/Parceiro6.png';
import Parceiro6 from '../assets/img/Parceiro5.png';
import Parceiro7 from '../assets/img/Parceiro7.png';
import Parceiro8 from '../assets/img/Parceiro8.png';
import Parceiro9 from '../assets/img/Parceiro9.png';
import Parceiro10 from '../assets/img/Parceiro10.png';
import Parceiro11 from '../assets/img/Parceiro11.png';
import Parceiro12 from '../assets/img/Parceiro12.png';

const Partners = () => {
  // Estado para controlar a transição para o fundo branco
  const [showWhiteBackground, setShowWhiteBackground] = useState(false);

  // Efeito para controlar a transição
  useEffect(() => {
    const handleScroll = () => {
      const element = document.getElementById('aqui');
      if (element) {
        const scrollPosition = window.scrollY;
        const elementOffsetTop = element.offsetTop;
        // Se o scroll passar do topo da div #aqui, mostramos o fundo branco
        setShowWhiteBackground(scrollPosition > elementOffsetTop);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <section
      id='partners'
      className={showWhiteBackground ? 'transition-all duration-[3000ms]' : 'bg-transparent transition-all duration-[3000ms]'}
    >
      <div className="w-full p-0">
        <h2 className="text-white text-center bg-gradient-to-r from-dark to-light text-xl lg:text-3xl font-extrabold uppercase py-6">
          Parceiros e Fornecedores
        </h2>
        <div className='container mx-auto'>
          <div className="flex flex-wrap justify-center py-10 lg:gap-10 items-center ">
          <img
              src={Parceiro1}
              alt="Partner 1"
              className="w-48 md:w-80 cursor-pointer"
              data-aos='fade-up'
              data-aos-offset='100'
              data-aos-delay='200'
            />
            <img
              src={Parceiro2}
              alt="Partner 2"
              className="w-48 md:w-80"
              data-aos='fade-up'
              data-aos-offset='100'
              data-aos-delay='300'
            />
            <img
              src={Parceiro3}
              alt="Partner 3"
              className="w-40 md:w-80"
              data-aos='fade-up'
              data-aos-offset='100'
              data-aos-delay='400'
            />
            <img
              src={Parceiro4}
              alt="Partner 4"
              className="w-40 md:w-80"
              data-aos='fade-up'
              data-aos-offset='100'
              data-aos-delay='500'
            />
            <img
              src={Parceiro5}
              alt="Partner 5"
              className="w-40 md:w-80"
              data-aos='fade-up'
              data-aos-offset='100'
              data-aos-delay='600'
            />
            <img
              src={Parceiro6}
              alt="Partner 6"
              className="w-40 md:w-80"
              data-aos='fade-up'
              data-aos-offset='100'
              data-aos-delay='700'
            />
            <img
              src={Parceiro7}
              alt="Partner 7"
              className="w-40 md:w-80"
              data-aos='fade-up'
              data-aos-offset='100'
              data-aos-delay='800'
            />
            <img
              src={Parceiro8}
              alt="Partner 8"
              className="w-40 md:w-80"
              data-aos='fade-up'
              data-aos-offset='100'
              data-aos-delay='900'
            />
            <img
              src={Parceiro9}
              alt="Partner 9"
              className="w-40 md:w-80"
              data-aos='fade-up'
              data-aos-offset='100'
              data-aos-delay='1000'
            />
            <img
              alt="Partner 10"
              src={Parceiro10}
              className="w-40 md:w-80"
              data-aos='fade-up'
              data-aos-offset='100'
              data-aos-delay='1100'
            />
            <img
              src={Parceiro11}
              alt="Partner 11"
              className="w-40 md:w-80"
              data-aos='fade-up'
              data-aos-offset='100'
              data-aos-delay='1200'
            />
            <img
              src={Parceiro12}
              alt="Partner 12"
              className="w-40 md:w-80"
              data-aos='fade-up'
              data-aos-offset='100'
              data-aos-delay='1300'
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Partners;
