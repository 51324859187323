import React from 'react';
//import data
import { footer } from '../data'
//import components
import Copyright from '../components/Copyright'


const Footer = () => {
  // destruturando footer data
  const { logo, links } = footer;
  return (
    <footer className='bg-dark text-white'>
      <div className='mx-auto px-4 lg:px-36'>
        <div className='flex flex-col items-center text-center lg:flex-row lg:text-left lg:items-center lg:justify-between py-8 gap-y-4'>
          {/* logo */}
          <a href='#'>
            <img src={logo} alt='' className='w-60 lg:w-80' />
          </a>
          <div className='flex flex-col lg:w-1/3 text-center items-center justify-center gap-y-6' >
            <h2 className='lg:text-white font-medium text-2xl lg:text-3xl text-main'>
              Energia Confiável
            </h2>
            <p className='text-light text-lg' > 
              Lider em soluções elétricas, garantindo segurança e qualidade em cada projeto.
            </p>
            <h1 className='text-light text-lg'>
              Solicite mais Informações
            </h1>
          </div>

          {/* lista de links */}
          <div className='text-center'>
            <div className='text-2xl uppercase font-medium mb-6 '>
              Links
            </div>
            <ul className='flex flex-col gap-y-3 w-60 lg:w-80'>
              {links.map((item, index) => {
                const { href, name } = item
                return <li key={index}>
                  <a className='font-medium hover:text-accent transition' href={href}>{name}</a>
                </li>
              })}
            </ul>
          </div>
        </div>
      </div>
      <div className='pb-4 lg:mx-36'>
        <hr className='pb-2'/>
        <Copyright />
      </div>
    </footer>
  );
};

export default Footer;
