import { AiOutlineLinkedin } from "react-icons/ai";
import { BsWhatsapp } from "react-icons/bs";
import { AiOutlineFacebook, AiOutlineInstagram } from "react-icons/ai";
import React from 'react'



const Social = () => {
  return (
    <div className="flex flex-col md:flex-row gap-y-2 md:gap-x-4 items-center justify-center text-center">
      <a
        className="text-dark hover:text-black/50 transition text:lg lg:text-2xl font-bold flex gap-x-4 items-center justify-center text-center"
        href="https://api.whatsapp.com/send?phone=5511947420443"
        target="_blank"
        rel="noopener noreferrer"
      >
        <BsWhatsapp className="text-2xl lg:text-4xl" />
        <span>(11) 9 4742-0443</span>
      </a>
      {/* <a
        className="text-dark hover:text-black/50 transition text:lg lg:text-2xl font-bold flex gap-x-4 items-center justify-center text-center"
        href="https://api.whatsapp.com/send?phone=5511947420443"
        target="_blank"
        rel="noopener noreferrer"
      >
        <AiOutlineInstagram className="text-2xl lg:text-4xl" />
        Flammarion_
      </a>
      <a
        className="text-dark hover:text-black/50 transition text:lg lg:text-2xl font-bold flex gap-x-4 items-center justify-center text-center"
        href="https://api.whatsapp.com/send?phone=5511947420443"
        target="_blank"
        rel="noopener noreferrer"
      >
        <AiOutlineFacebook className="text-2xl lg:text-4xl" />
        <span>Flammarion_</span>
      </a>
      <a
        className="text-dark hover:text-black/50 transition text:lg lg:text-2xl font-bold flex gap-x-4 items-center justify-center text-center"
        href="https://api.whatsapp.com/send?phone=5511947420443"
        target="_blank"
        rel="noopener noreferrer"
      >
        <AiOutlineLinkedin className="text-2xl lg:text-4xl" />
        <span>Flammarion_</span>
      </a> */}
    </div>
  )
}

export default Social