import React from 'react';

// import aos
import Aos from 'aos';
// import aos css
import 'aos/dist/aos.css';

// import components
import Header from './components/Header';
import Hero from './components/Hero';
import Footer from './components/Footer';
import Service from './components/Service';
import About from './components/About';
import Partners from './components/Partners';
import Contact from './components/Contact';


const App = () => {
  Aos.init({
    duration: 1800,
    offset: 100,
  })
  return (
    <>
      <Header />
      <Hero />
      <About />
      <Service />
      <Partners />
      <Contact />
      <Footer />
    </>
  );
};

export default App;
