import React from 'react';

import { SiWhatsapp } from "react-icons/si";
import { TbWorldWww } from "react-icons/tb";
import { SlSocialFacebook } from "react-icons/sl";
import { SlSocialLinkedin } from "react-icons/sl";
import { SlSocialInstagram } from "react-icons/sl";

const Copyright = () => {
  //destruturando copyright
  return (
    <div className='w-full flex justify-between text-xl px-4'>
      {/* links */}
      <div className='flex mb-3'>
        <a className='text-main hover:text-main/50 transition text-lg' href='https://www.mocatech.com.br/' target="_blank"
          rel="noopener noreferrer">Copyright @ 2024 Mocatech</a>
      </div>
      {/* social icons */}
      <div className='flex gap-x-4 text-2xl text-main'>
        <SiWhatsapp className='hover:text-main/50' />
        <SlSocialFacebook className='hover:text-main/50' />
        <SlSocialInstagram className='hover:text-main/50' />
      </div>
    </div>
  );
};

export default Copyright;
