import React from 'react';

//import swiper react components
import { Swiper, SwiperSlide } from 'swiper/react'
//import swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import '../swiper.css';
//import required modules
import { Pagination, Navigation, Autoplay } from 'swiper'
//import data
import { serviceSlider } from '../data';
import { HiCheck } from 'react-icons/hi'


const ServicesSlider = () => {
    return (
        <Swiper modules={[Pagination, Navigation, Autoplay]}
            autoplay={true}
            loop={true}
            pagination={{
                clickable: true,
                dynamicBullets: true,
            }}
            navigation={true}
            className='serviceSlider'
        >
            {serviceSlider.map((slide, index) => {
                //destructure slide

                const { title, subtitle, image, btnIcon, btnLink, link } = slide;
                return <SwiperSlide
                    className=''
                    key={index}>
                    
                    <div className='flex flex-col justify-between items-start lg:flex-row' data-aos='fade-right' data-aos-offset='100' data-aos-delay='200'>

                        {/* Imagem */}
                        <div className='flex-1 flex justify-center'>
                            <img
                                className='p-14'
                                src={image.type}
                                alt=''
                            />
                        </div>

                        {/* Texto */}
                        <div className='flex-1 pl-4 lg:pl-0 lg:mt-56' data-aos='fade-left' data-aos-offset='100' data-aos-delay='200' >
                            <p className='text-main text-xl lg:text-3xl font-extrabold uppercase pb-4'>{title} </p>
                            <ul className='  text-base lg:text-[18px] lg:leading-8  mb-8'>
                                {subtitle.map((item) => {
                                    //destruturando links
                                    const { name } = item
                                    return <li className='flex items-center text-left gap-x-3  lg:text-2xl font-light text-second'>
                                        <HiCheck className='text-main' />
                                        {name}
                                    </li>
                                })}
                            </ul>

                            <button className='btn-link flex items-center gap-x-3 hover:gap-x-5 transition-all mb-5'>
                                <a href={link} target="_blank" rel="noreferrer" >{btnLink}</a>
                                <img src={btnIcon} className='animate-pulse text-main' alt='' />
                            </button>
                        </div>

                    </div>

                </SwiperSlide>;
            })}
        </Swiper>
    );
};

export default ServicesSlider;