import { IoLogoWhatsapp } from "react-icons/io"; 
import React, { useState, useEffect } from "react";

const Whatsapp = () => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollPercentage = (scrollPosition / (documentHeight - windowHeight)) * 100;

      // Defina a porcentagem de rolagem na qual o botão deve se tornar invisível
      const scrollThreshold = 99;
      const scrollInitiation = 5;
       

      setIsVisible(scrollPercentage >= scrollInitiation && scrollPercentage <= scrollThreshold);
    };

    // Adicione um listener de evento de scroll
    window.addEventListener('scroll', handleScroll);

    // Retorne uma função para remover o listener de evento quando o componente for desmontado
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // Dependência vazia para que o useEffect seja executado apenas uma vez

  return (
    <section className="fixed z-50 left-8 bottom-8 flex items-center gap-x-3 group">
      <div className="transition-all duration-500">
        {isVisible && (
          <div className={'flex items-center justify-center text-center rounded-full opacity-100 transition-opacity duration-500 ease-in-out filter drop-shadow-lg mb-5 animate-pulse'}>
            <a
              href="https://api.whatsapp.com/send?phone=5511947420443"
              target="_blank"
              rel="noopener noreferrer"
              className="flex-1 text-[#006400] transition-all duration-300"
            >
              <IoLogoWhatsapp className="text-5xl lg:text-7xl" />
            </a>
          </div>
        )}
      </div>
    </section>
  );
};

export default Whatsapp;
