import React from 'react';
import { about } from '../data';
import ButtonContact from './ButtonContact';


const About = () => {
  return (
    <section id='About' className='h-auto flex flex-col justify-center items-center bg-aboutbg bg-no-repeat bg-cover bg-center lg:py-4'>
      <div className='container mx-auto lg:p-20 p-4 lg:pt-28 z-10 '>
        {about.map((text, index) => {
          const { title, subtitle } = text;
          return (
            <div key={index} className='text-center'>
              <h1 className='text-main text-3xl font-extrabold mb-4 uppercase'>{title}</h1>
              <p className='w-full lg:w-2/3 mx-auto text-2xl font-light text-second'>
                {subtitle.map((item, index) => {
                  // Destruturando links
                  const { name } = item; 
                  return (
                    <span key={index} className="block">
                      {name}
                      <br /> 
                    </span>
                  );
                })}
              </p>
              {index !== about.length - 1 && <div className="h-16 lg:h-28 w-1 mx-auto my-6 bg-main rounded-full shadow-2xl shadow-main "></div>}
            </div>
          );
        })}
      </div>
        <div className='flex justify-center pb-10'>
          <ButtonContact />
        </div>
    </section>
  );
};

export default About;
