import React from 'react'

const ButtonContact = () => {
  return (
    <div className=''>
      <a
        href="https://api.whatsapp.com/send?phone=5511947420443"
        target="_blank"
        rel="noopener noreferrer"
        className='btn btn-sm btn-outline w-80 h-14 shadow-2xl'
      >
        Conheça Mais
      </a>
    </div>
  )
}

export default ButtonContact